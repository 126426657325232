html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Open Sans";
  box-sizing: border-box;
  position: relative;
}
.section {
  padding: 5rem 1rem;
}

.sectionBG {
  box-shadow: 0 0 0 100vmax #f5eddc;
  clip-path: inset(0 -100vmax);
}
/* .btn {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.btn:active {
  box-shadow: 0 0 40px 40px #eb1d36 inset;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .btn:hover {
    box-shadow: 0 0 40px 40px #eb1d36 inset;
    color: #fff;
  }
} */
.btn {
  position: relative;
  display: inline-block;
  background-color: #f68989;
  box-shadow: 0px 5px 0px 0px #bb6464, 2px 0px 0px 0px #bb6464,
    2px 5px 0px 0px #bb6464;
}
.btn:hover {
  background-color: #fa9494;
}
.btn:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px #bb6464, 1px 0px 0px 0px #bb6464,
    1px 1px 0px 0px #bb6464;
}
